import { init } from '@paralleldrive/cuid2'
import {
  type AnyColumn,
  type GetColumnData,
  type SQL,
  type SelectedFields,
  and,
  is,
  sql
} from 'drizzle-orm'
import { toSnakeCase } from 'drizzle-orm/casing'
import type { PgColumn } from 'drizzle-orm/pg-core'
import { PgTimestampString } from 'drizzle-orm/pg-core'
import type { SelectResultFields } from 'drizzle-orm/query-builders/select.types'

type DatabaseErrorType = 'duplicate' | 'deadlock'

const POSTGRES_ERROR_CODES: Record<DatabaseErrorType, string> = {
  duplicate: '23505',
  deadlock: '40P01'
}

export function isDatabaseError(e: unknown, type: DatabaseErrorType) {
  if (typeof e === 'object' && e !== null && 'code' in e) {
    const error = e as { code: string }
    return POSTGRES_ERROR_CODES[type] === error.code
  }
  return false
}

export function generateId(length = 23) {
  return init({ length })()
}

export function generateCode(digits = 6) {
  const min = 10 ** (digits - 1)
  const max = 10 ** digits - 1
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function calculatePageCount(pageSize: number) {
  return sql<number>`ceil((count(*) over())::numeric / ${pageSize})::int`
}

export function excluded(column: PgColumn) {
  return sql.raw(`EXCLUDED.${toSnakeCase(column.name)}`)
}

// Source: https://drizzle.run/lkd38uqtk5broj117asmxkah

type ColumnData<Column extends PgColumn> = Column['_']['data']

export function mergeJson<
  Column extends PgColumn,
  CustomType extends ColumnData<Column> = ColumnData<Column>
>(column: Column, data: Partial<CustomType> | null): SQL
export function mergeJson<
  Column extends PgColumn,
  CustomType extends ColumnData<Column> = ColumnData<Column>,
  Key extends keyof CustomType = keyof CustomType
>(column: Column, field: Key, data: Partial<CustomType[Key]> | null): SQL
export function mergeJson<
  Column extends PgColumn,
  CustomType extends ColumnData<Column> = ColumnData<Column>,
  Key extends keyof CustomType = keyof CustomType
>(column: Column, fieldOrData: Key | Partial<CustomType>, data?: Partial<CustomType[Key]> | null) {
  if (typeof fieldOrData === 'string') {
    return sql`jsonb_set(${column}, '{${sql.raw(String(fieldOrData))}}', ${data ? sql`${column} -> '${sql.raw(String(fieldOrData))}' || ${JSON.stringify(data)}` : 'null'})`
  }
  return sql`coalesce(${column}, '{}') || ${fieldOrData ? JSON.stringify(fieldOrData) : null}`
}

// Source for jsonBuildObject, jsonAggBuildObject, and arrayAgg: https://drizzle.run/se2noay5mhdu24va3xhv0lqo

function jsonBuildObject<T extends SelectedFields<any, any>>(shape: T) {
  const chunks: SQL[] = []

  // biome-ignore lint/complexity/noForEach: simpler to use forEach
  Object.entries(shape)
    .filter(([_, value]) => value)
    .forEach(([key, value]) => {
      if (chunks.length > 0) {
        chunks.push(sql.raw(`,`))
      }

      chunks.push(sql.raw(`'${key}',`))

      // json_build_object formats to ISO 8601 ...
      if (is(value, PgTimestampString)) {
        chunks.push(sql`timezone('UTC', ${value})`)
      } else {
        chunks.push(sql`${value}`)
      }
    })

  return sql<SelectResultFields<T>>`json_build_object(${sql.join(chunks)})`
}

export function jsonAggBuildObject<T extends SelectedFields<any, any>, Column extends AnyColumn>(
  shape: T,
  options?: { orderBy?: { colName: Column; direction: 'ASC' | 'DESC' } }
) {
  return sql<SelectResultFields<T>[]>`coalesce(
    json_agg(${jsonBuildObject(shape)}
    ${
      options?.orderBy
        ? sql`ORDER BY ${options.orderBy.colName} ${sql.raw(options.orderBy.direction)}`
        : undefined
    })
    FILTER (WHERE ${and(
      sql.join(
        Object.values(shape)
          .filter((value) => value)
          .map((value) => sql`${sql`${value}`} IS NOT NULL`),
        sql` AND `
      )
    )})
    ,'${sql`[]`}')`
}

export function arrayAgg<Column extends AnyColumn>(column: Column) {
  return sql<GetColumnData<Column, 'raw'>[]>`
    coalesce(
      json_agg(distinct ${sql`${column}`}) filter (where ${column} is not null),
      '[]'
    )
  `
}

export const cleanCategoryName = (name: string) => {
  const suffixesToRemove = ['Software', 'Tools']
  const suffixPattern = new RegExp(`\\s*(${suffixesToRemove.join('|')})\\s*$`, 'i')
  return name.replace(suffixPattern, '').trim()
}
