import { type SQL, sql } from 'drizzle-orm'
import {
  customType,
  foreignKey,
  index,
  pgSchema,
  pgTable,
  primaryKey,
  timestamp,
  unique
} from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'
import { cleanCategoryName, generateId } from './utils/helpers'

/**** Schemas ****/

export const authSchema = pgSchema('auth')
export const extensionSchema = pgSchema('extension')
export const directorySchema = pgSchema('directory')

/**** Shared Columns ****/

const timestamps = {
  createdAt: timestamp({ mode: 'date', withTimezone: true }).notNull().defaultNow(),
  updatedAt: timestamp({ mode: 'date', withTimezone: true })
    .notNull()
    .defaultNow()
    .$onUpdate(() => new Date())
}

/**** Custom Types ****/

export const customJsonb = <T extends z.ZodTypeAny>(schema: T) => {
  return customType<{ data: z.infer<T>; driverData: string }>({
    dataType() {
      return 'jsonb'
    },
    toDriver(value: z.infer<T>): string {
      return JSON.stringify(schema.parse(value))
    },
    fromDriver(value: unknown) {
      return schema.parse(value) as z.infer<T>
    }
  })()
}

export const customEnum = <K extends string, T extends z.ZodEnum<[K, ...K[]]>>(schema: T) => {
  return customType<{ data: z.infer<T>; driverData: string }>({
    dataType() {
      return 'text'
    },
    toDriver(value: z.infer<T>): string {
      const safeValue = schema.parse(value)
      return safeValue
    },
    fromDriver(value: string) {
      return schema.parse(value) as z.infer<T>
    }
  })()
}

/**** #Enums ****/

export const AuthProvidersEnum = z.enum(['google', 'microsoft', 'workspace'])

export const DirectoryProvidersEnum = AuthProvidersEnum.extract(['workspace'])

export const DirectoryWebhookStatusEnum = z.enum(['active', 'replaced', 'disabled'])

export const SessionSourceEnum = z.enum(['extension', 'google'])

/**** #Auth Sessions ****/

export const AuthSessions = authSchema.table('sessions', (t) => ({
  id: t
    .text()
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  userId: t
    .text()
    .notNull()
    .unique('sessions_user_id_unique')
    .references(() => Users.id, { onDelete: 'cascade' }),
  expiresAt: t
    .timestamp({
      mode: 'date',
      withTimezone: true
    })
    .notNull()
    .default(sql`NOW() + INTERVAL '1 hour'`),
  ...timestamps
}))

export type AuthSession = typeof AuthSessions.$inferSelect
export type AuthSessionInsert = typeof AuthSessions.$inferInsert

export const insertAuthSessionSchema = createInsertSchema(AuthSessions)
export const selectAuthSessionSchema = createSelectSchema(AuthSessions)

/**** #Auth Providers ****/

export const AuthProviders = authSchema.table(
  'providers',
  (t) => ({
    provider: customEnum(AuthProvidersEnum.exclude(['workspace'])).notNull(),
    providerId: t.text().notNull(),
    userId: t
      .text()
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    createdAt: timestamps.createdAt
  }),
  (t) => [primaryKey({ columns: [t.provider, t.providerId] })]
)

export type AuthProvider = typeof AuthProviders.$inferSelect
export type AuthProviderInsert = typeof AuthProviders.$inferInsert

/**** #Auth Invitations ****/

export const AuthInvitations = authSchema.table('invitations', (t) => ({
  id: t
    .text()
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  orgId: t
    .text()
    .notNull()
    .references(() => Organizations.id, { onDelete: 'cascade' }),
  createdAt: timestamps.createdAt
}))

export type AuthInvitation = typeof AuthInvitations.$inferSelect
export type AuthInvitationInsert = typeof AuthInvitations.$inferInsert

export const insertInvitationSchema = createInsertSchema(AuthInvitations)
export const selectInvitationSchema = createSelectSchema(AuthInvitations)

/**** #Auth Blacklist ****/

export const Blacklist = authSchema.table('blacklist', (t) => ({
  domain: t.text().primaryKey().notNull()
}))

export type AuthBlacklist = typeof Blacklist.$inferSelect
export type AuthBlacklistInsert = typeof Blacklist.$inferInsert

/**** #Users ****/

export const Users = pgTable('users', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  email: t.text().notNull().unique('users_email_unique'),
  profileId: t
    .integer()
    .notNull()
    .references(() => Profiles.id, { onDelete: 'restrict' }),
  lastSignInAt: t.timestamp({
    mode: 'date',
    withTimezone: true
  }),
  isOnboarded: t.boolean().notNull().default(false),
  isInternal: t
    .boolean()
    .notNull()
    .generatedAlwaysAs((): SQL => sql`${Users.email} LIKE '%@hapstack.com'`),
  ...timestamps
}))

export type User = typeof Users.$inferSelect
export type UserInsert = typeof Users.$inferInsert

export const insertUserSchema = createInsertSchema(Users)
export const selectUserSchema = createSelectSchema(Users)

/**** #Organizations ****/

export const Organizations = pgTable('organizations', (t) => ({
  id: t
    .text()
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  name: t.text().notNull(),
  createdBy: t.text().references(() => Users.id, { onDelete: 'set null' }),
  imageUrl: t.text(),
  ...timestamps
}))

export type Organization = typeof Organizations.$inferSelect
export type OrganizationInsert = typeof Organizations.$inferInsert

export const insertOrganizationSchema = createInsertSchema(Organizations)
export const selectOrganizationSchema = createSelectSchema(Organizations)

/**** #Memberships ****/

export const Memberships = pgTable(
  'memberships',
  (t) => ({
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    userId: t
      .text()
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    isActive: t.boolean().notNull().default(true),
    createdAt: timestamps.createdAt
  }),
  (t) => [primaryKey({ columns: [t.orgId, t.userId] })]
)

export type Membership = typeof Memberships.$inferSelect
export type MembershipInsert = typeof Memberships.$inferInsert

export const insertMembershipSchema = createInsertSchema(Memberships)
export const selectMembershipSchema = createSelectSchema(Memberships)

/**** #Employees ****/

export const Employees = pgTable(
  'employees',
  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId(12)),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    email: t.text().notNull().unique('employees_email_unique'),
    profileId: t
      .integer()
      .notNull()
      .references(() => Profiles.id, { onDelete: 'restrict' }),
    isTracked: t.boolean().notNull().default(true),
    ...timestamps
  }),
  (t) => [index('employees_org_id_index').on(t.orgId)]
)

export type Employee = typeof Employees.$inferSelect
export type EmployeeInsert = typeof Employees.$inferInsert

export const insertEmployeeSchema = createInsertSchema(Employees)
export const selectEmployeeSchema = createSelectSchema(Employees)

/**** #Profiles ****/

export const Profiles = pgTable('profiles', (t) => ({
  id: t.integer().primaryKey().generatedByDefaultAsIdentity(),
  firstName: t.text(),
  lastName: t.text(),
  displayName: t
    .text()
    .notNull()
    .generatedAlwaysAs((): SQL => sql`${Profiles.firstName} || ' ' || ${Profiles.lastName}`),
  fullName: t
    .text()
    .notNull()
    .generatedAlwaysAs((): SQL => sql`${Profiles.firstName} || ' ' || ${Profiles.lastName}`),
  avatarUrl: t.text(),
  ...timestamps
}))

export type Profile = typeof Profiles.$inferSelect
export type ProfileInsert = typeof Profiles.$inferInsert

export const insertProfileSchema = createInsertSchema(Profiles)
export const selectProfileSchema = createSelectSchema(Profiles)

/**** #Groups ****/

export const Groups = pgTable(
  'groups',
  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId(12)),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    name: t.text().notNull(),
    description: t.text(),
    ...timestamps
  }),
  (t) => [unique('groups_org_id_name_unique').on(t.orgId, t.name)]
)

export type Group = typeof Groups.$inferSelect
export type GroupInsert = typeof Groups.$inferInsert

export const insertGroupSchema = createInsertSchema(Groups)
export const selectGroupSchema = createSelectSchema(Groups, {
  name: ({ name }) => name.trim().min(1, 'This field is required.')
})

/**** #Group Memberships ****/

export const GroupMemberships = pgTable(
  'group_memberships',
  (t) => ({
    groupId: t
      .text()
      .notNull()
      .references(() => Groups.id, { onDelete: 'cascade' }),
    employeeId: t
      .text()
      .notNull()
      .references(() => Employees.id, { onDelete: 'cascade' }),
    createdAt: timestamps.createdAt
  }),
  (t) => [primaryKey({ columns: [t.groupId, t.employeeId] })]
)

export type GroupMembership = typeof GroupMemberships.$inferSelect
export type GroupMembershipInsert = typeof GroupMemberships.$inferInsert

export const insertGroupMembershipSchema = createInsertSchema(GroupMemberships)
export const selectGroupMembershipSchema = createSelectSchema(GroupMemberships)

/**** #Vendors ****/

export const Vendors = pgTable(
  'vendors',
  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId(8)),
    name: t.text().notNull(),
    tagline: t.text().notNull(),
    iconUrl: t.text().notNull(),
    website: t.text().notNull(),
    originalId: t.integer().unique('vendors_original_id_unique'),
    ...timestamps
  }),
  (t) => [index('vendors_name_trgm_index').using('gin', sql`${t.name} gin_trgm_ops`)]
)

export type Vendor = typeof Vendors.$inferSelect
export type VendorInsert = typeof Vendors.$inferInsert

export const insertVendorSchema = createInsertSchema(Vendors, {
  iconUrl: (schema) => schema.iconUrl.url(),
  website: (schema) => schema.website.url(),
  name: (schema) => schema.name.trim().min(1),
  tagline: (schema) => schema.tagline.trim().min(1)
})
export const selectVendorSchema = createSelectSchema(Vendors, {
  iconUrl: (schema) => schema.iconUrl.url(),
  website: (schema) => schema.website.url()
})

/**** #Tags ****/

export const Tags = pgTable('tags', (t) => ({
  id: t.integer().primaryKey().generatedAlwaysAsIdentity(),
  name: t.text().unique('tags_name_unique').notNull(),
  isDefault: t.boolean().notNull().default(false),
  createdAt: timestamps.createdAt
}))

export type Tag = typeof Tags.$inferSelect
export type TagInsert = typeof Tags.$inferInsert

export const insertTagSchema = createInsertSchema(Tags)
export const selectTagSchema = createSelectSchema(Tags)

/**** #Tag Colors ****/

export const TagColors = pgTable('tag_colors', (t) => ({
  id: t.integer().primaryKey().generatedAlwaysAsIdentity(),
  backgroundColor: t.text().notNull(),
  textColor: t.text().notNull(),
  name: t.text().notNull().unique('tag_colors_name_unique')
}))

export type TagColor = typeof TagColors.$inferSelect
export type TagColorInsert = typeof TagColors.$inferInsert

export const insertTagColorSchema = createInsertSchema(TagColors)
export const selectTagColorSchema = createSelectSchema(TagColors)

/**** #Organization Tags ****/

export const OrganizationTags = pgTable(
  'organization_tags',
  (t) => ({
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    tagId: t
      .integer()
      .notNull()
      .references(() => Tags.id, { onDelete: 'cascade' }),
    colorId: t
      .integer()
      .notNull()
      .references(() => TagColors.id, {
        onDelete: 'restrict'
      })
  }),
  (table) => [primaryKey({ columns: [table.orgId, table.tagId] })]
)

export type OrganizationTag = typeof OrganizationTags.$inferSelect
export type OrganizationTagInsert = typeof OrganizationTags.$inferInsert

export const insertOrganizationTagSchema = createInsertSchema(OrganizationTags)
export const selectOrganizationTagSchema = createSelectSchema(OrganizationTags)

/**** #Vendor Tags ****/

export const VendorTags = pgTable(
  'vendor_tags',
  (t) => ({
    vendorId: t
      .text()
      .notNull()
      .references(() => Vendors.id, { onDelete: 'cascade' }),
    tagId: t
      .integer()
      .notNull()
      .references(() => Tags.id, { onDelete: 'cascade' }),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' })
  }),
  (t) => [
    foreignKey({
      columns: [t.orgId, t.tagId],
      foreignColumns: [OrganizationTags.orgId, OrganizationTags.tagId]
    }).onDelete('cascade'),
    primaryKey({ columns: [t.vendorId, t.tagId, t.orgId] })
  ]
)

export type VendorTag = typeof VendorTags.$inferSelect
export type VendorTagInsert = typeof VendorTags.$inferInsert

export const insertVendorTagSchema = createInsertSchema(VendorTags)
export const selectVendorTagSchema = createSelectSchema(VendorTags)

/**** #Favorited Vendors ****/

export const FavoritedVendors = pgTable(
  'favorited_vendors',
  (t) => ({
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    vendorId: t
      .text()
      .notNull()
      .references(() => Vendors.id, { onDelete: 'cascade' }),
    createdAt: timestamps.createdAt
  }),
  (table) => [primaryKey({ columns: [table.orgId, table.vendorId] })]
)

export type FavoritedVendor = typeof FavoritedVendors.$inferSelect
export type FavoritedVendorInsert = typeof FavoritedVendors.$inferInsert

/**** #Products ****/

export const Products = pgTable(
  'products',
  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId(10)),
    name: t.text().notNull(),
    iconUrl: t.text(),
    vendorId: t
      .text()
      .notNull()
      .references(() => Vendors.id, { onDelete: 'cascade' }),
    originalVendorId: t.integer(),
    ...timestamps
  }),
  (t) => [
    unique('products_vendor_id_name_unique').on(t.vendorId, t.name),
    index('products_vendor_id_index').on(t.vendorId)
  ]
)

export type Product = typeof Products.$inferSelect
export type ProductInsert = typeof Products.$inferInsert

export const insertProductSchema = createInsertSchema(Products, {
  iconUrl: (schema) => schema.iconUrl.url()
})
export const selectProductSchema = createSelectSchema(Products)

/**** #Urls ****/

export const Urls = pgTable(
  'urls',
  (t) => ({
    id: t
      .text()
      .primaryKey()
      .$defaultFn(() => generateId()),
    productId: t
      .text()
      .notNull()
      .references(() => Products.id, {
        onDelete: 'cascade'
      }),
    subdomainPattern: t.text().notNull().default('.*'),
    domain: t.text().notNull(),
    tldPattern: t.text().notNull(),
    pathPattern: t.text().notNull().default('/*'),
    notes: t.text(),
    ...timestamps
  }),
  (table) => [
    unique('urls_product_id_subdomain_pattern_domain_path_pattern_unique').on(
      table.productId,
      table.subdomainPattern,
      table.domain,
      table.pathPattern
    ),
    index('urls_product_id_index').on(table.productId)
  ]
)

export type Url = typeof Urls.$inferSelect
export type UrlInsert = typeof Urls.$inferInsert

export const insertUrlSchema = createInsertSchema(Urls)
export const selectUrlSchema = createSelectSchema(Urls)

/**** #Sessions ****/

export const Sessions = pgTable(
  'sessions',
  (t) => ({
    id: t.bigint({ mode: 'number' }).primaryKey().generatedAlwaysAsIdentity(),
    employeeId: t
      .text()
      .notNull()
      .references(() => Employees.id, { onDelete: 'cascade' }),
    urlId: t
      .text()
      .references(() => Urls.id, { onDelete: 'cascade' })
      .notNull(),
    date: t.date({ mode: 'date' }).notNull(),
    pageviews: t.integer().notNull().default(1),
    url: t.text('url').notNull(),
    source: customEnum(SessionSourceEnum).notNull(),
    ...timestamps
  }),
  (t) => [
    unique('sessions_employee_id_url_id_date_unique').on(t.employeeId, t.urlId, t.date),
    index('sessions_employee_id_index').on(t.employeeId),
    index('sessions_url_id_index').on(t.urlId)
  ]
)

export type Session = typeof Sessions.$inferSelect
export type SessionInsert = typeof Sessions.$inferInsert

export const insertSessionSchema = createInsertSchema(Sessions)
export const selectSessionSchema = createSelectSchema(Sessions, {
  source: SessionSourceEnum
})

/**** #Categories ****/

export const Categories = pgTable(
  'categories',
  (t) => ({
    id: t.integer().primaryKey().generatedAlwaysAsIdentity(),
    name: t.text().notNull(),
    slug: t.text().unique('categories_slug_unique'),
    parentId: t.integer(),
    ...timestamps
  }),
  (t) => {
    return [
      foreignKey({
        columns: [t.parentId],
        foreignColumns: [t.id]
      }),
      unique('categories_name_parent_id_unique').on(t.name, t.parentId)
    ]
  }
)

export type Category = typeof Categories.$inferSelect
export type CategoryInsert = typeof Categories.$inferInsert

export const insertCategorySchema = createInsertSchema(Categories)
export const selectCategorySchema = createSelectSchema(Categories, {
  name: (schema) => schema.name.transform(cleanCategoryName)
})

/**** #Product Categories ****/

export const ProductCategories = pgTable(
  'product_categories',
  (t) => ({
    productId: t
      .text()
      .notNull()
      .references(() => Products.id, { onDelete: 'cascade' }),
    categoryId: t
      .integer()
      .notNull()
      .references(() => Categories.id, { onDelete: 'cascade' }),
    createdAt: timestamps.createdAt
  }),
  (t) => [primaryKey({ columns: [t.productId, t.categoryId] })]
)

export type ProductCategory = typeof ProductCategories.$inferSelect
export type ProductCategoryInsert = typeof ProductCategories.$inferInsert

export const insertProductCategorySchema = createInsertSchema(ProductCategories)
export const selectProductCategorySchema = createSelectSchema(ProductCategories)

/**** #Directory Providers ****/

export const DirectoryProviders = directorySchema.table('providers', (t) => ({
  id: t.uuid().primaryKey().defaultRandom(),
  name: customEnum(DirectoryProvidersEnum).notNull().unique(),
  label: t.text().notNull(),
  ...timestamps
}))

export const insertDirectoryProviderSchema = createInsertSchema(DirectoryProviders)
export const selectDirectoryProviderSchema = createSelectSchema(DirectoryProviders, {
  name: DirectoryProvidersEnum
})

export type DirectoryProvider = typeof DirectoryProviders.$inferSelect
export type DirectoryProviderInsert = typeof DirectoryProviders.$inferInsert

/**** #Directory Integration ****/

const WorkspaceCredentialsSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string()
})

const WorkspaceConfigSchema = z
  .object({
    syncRootOrgUnit: z.boolean()
  })
  .default({ syncRootOrgUnit: true })

export const DirectoryIntegrations = directorySchema.table(
  'integrations',
  (t) => ({
    id: t.uuid().primaryKey().defaultRandom(),
    orgId: t
      .text()
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    providerId: t
      .uuid()
      .notNull()
      .references(() => DirectoryProviders.id, { onDelete: 'cascade' }),
    isEnabled: t.boolean().notNull().default(true),
    credentials: customJsonb(WorkspaceCredentialsSchema).notNull(),
    config: customJsonb(WorkspaceConfigSchema)
      .notNull()
      .default(WorkspaceConfigSchema.parse(undefined)),
    ...timestamps
  }),
  (t) => [unique('integrations_org_id_provider_id_unique').on(t.orgId, t.providerId)]
)

export type DirectoryIntegration = typeof DirectoryIntegrations.$inferSelect
export type DirectoryIntegrationInsert = typeof DirectoryIntegrations.$inferInsert

export const insertDirectoryIntegrationSchema = createInsertSchema(DirectoryIntegrations)
export const selectDirectoryIntegrationSchema = createSelectSchema(DirectoryIntegrations)

/**** #Directory Identities ****/

export const DirectoryIdentities = directorySchema.table(
  'identities',
  (t) => ({
    id: t.uuid().primaryKey().defaultRandom(),
    employeeId: t
      .text()
      .notNull()
      .references(() => Employees.id, { onDelete: 'cascade' }),
    email: t.text().notNull(),
    integrationId: t
      .uuid()
      .notNull()
      .references(() => DirectoryIntegrations.id, { onDelete: 'cascade' }),
    externalId: t.text().notNull(),
    rawData: t.jsonb().notNull(),
    isSuspended: t.boolean().notNull().default(false),
    isArchived: t.boolean().notNull().default(false),
    isDeleted: t.boolean().notNull().default(false),
    lastSyncedAt: t.timestamp({ mode: 'date', withTimezone: true }).notNull().defaultNow(),
    ...timestamps
  }),
  (t) => [
    unique('identities_integration_id_external_id_unique').on(t.integrationId, t.externalId),
    index('identities_employee_id_index').on(t.email),
    index('identities_integration_id_index').on(t.integrationId)
  ]
)

export type DirectoryIdentity = typeof DirectoryIdentities.$inferSelect
export type DirectoryIdentityInsert = typeof DirectoryIdentities.$inferInsert

/**** #Directory Org Units ****/

export const DirectoryOrgUnits = directorySchema.table(
  'org_units',
  (t) => ({
    id: t.uuid().primaryKey().defaultRandom(),
    integrationId: t
      .uuid()
      .notNull()
      .references(() => DirectoryIntegrations.id, { onDelete: 'cascade' }),
    orgUnitId: t.text().notNull(),
    orgUnitPath: t.text().notNull(),
    name: t.text().notNull(),
    parentOrgUnitId: t.text(),
    parentOrgUnitPath: t.text(),
    isSynced: t.boolean().notNull().default(false),
    ...timestamps
  }),
  (t) => [unique('org_units_integration_id_org_unit_id_unique').on(t.integrationId, t.orgUnitId)]
)

export type DirectoryOrgUnit = typeof DirectoryOrgUnits.$inferSelect
export type DirectoryOrgUnitInsert = typeof DirectoryOrgUnits.$inferInsert

/**** #Directory Webhooks ****/

export const DirectoryWebhooks = directorySchema.table('webhooks', (t) => ({
  id: t.uuid().primaryKey().defaultRandom(),
  integrationId: t
    .uuid()
    .notNull()
    .references(() => DirectoryIntegrations.id, { onDelete: 'cascade' }),
  resourceId: t.text(),
  expiresAt: t.timestamp({ mode: 'date', withTimezone: true }),
  status: customEnum(DirectoryWebhookStatusEnum).notNull().default('active'),
  lastNotificationAt: t.timestamp({ mode: 'date', withTimezone: true }),
  ...timestamps
}))

export type DirectoryWebhook = typeof DirectoryWebhooks.$inferSelect
export type DirectoryWebhookInsert = typeof DirectoryWebhooks.$inferInsert

/**** #Extension Auth Sessions ****/

export const ExtensionAuthSessions = extensionSchema.table('sessions', (t) => ({
  id: t
    .text()
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  employeeId: t
    .text()
    .notNull()
    .unique('extension_sessions_employee_id_unique')
    .references(() => Employees.id, { onDelete: 'cascade' }),
  profileId: t.text(),
  expiresAt: t
    .timestamp({ mode: 'date', withTimezone: true })
    .notNull()
    .default(sql`NOW() + INTERVAL '10 years'`),
  createdAt: timestamps.createdAt
}))

export type ExtensionAuthSession = typeof ExtensionAuthSessions.$inferSelect
export type ExtensionAuthSessionInsert = typeof ExtensionAuthSessions.$inferInsert

export const insertExtensionAuthSessionSchema = createInsertSchema(ExtensionAuthSessions)
export const selectExtensionAuthSessionSchema = createSelectSchema(ExtensionAuthSessions)
